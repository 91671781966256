import { FormEvent, useEffect, useState } from "react";
import { UserState, selectUser } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import CustomTextField from "../Common/CustomTextField";
import Navbar from "../Navbar/Navbar";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import { allRoutes } from "../../Routes/AllRoutes";
import { getProfile, updateProfile } from "../../Services/profileService";
import ImageUploader from "../Common/ImageUploader";
import * as EmailValidator from "email-validator";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import OtpVerifyDialog from "./OtpVerifyDialog";
import { validatePassword } from "../../Utils/utils";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";

interface AccountSettingsData extends UserState {
	changePassword?: string;
	addressObject?: PlaceType | null;
}

const defaultData = {
	name: "",
	lastName: "",
	email: "",
	addressObject: null,
	bill: "",
	phone: "",
	password: "",
	changePassword: "",
	notificationPreferences: {
		inApp: false,
		email: false,
	},
};

const AccountSettings = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [otpDialog, setOtpDialog] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const [updatingEmail, setUpdatingEmail] = useState<any>("");

	useEffect(() => {
		if (user) {
			const userData = {
				name: user.name,
				lastName: user.lastName,
				email: user.email,
				addressObject: user.addressObject
					? user.addressObject
					: user.address
						? {
							description: user.address,
							structured_formatting: {
								main_text: user.address,
								secondary_text: user.address,
							},
						}
						: null,
				bill: user.bill,
				phone: user.phone,

				notificationPreferences: {
					inApp: user.notificationPreferences?.inApp,
					email: user.notificationPreferences?.email,
				},
			};
			setData(userData);
			setProfilePicture(user.picture);
		}
	}, [user]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const handleClickCheckbox = (key: "inApp" | "email") => {
		setData({
			...data,
			notificationPreferences: {
				...data.notificationPreferences,
				[key]: !data.notificationPreferences?.[key],
			},
		});
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };
		delete updatedErrors.notificationPreferences;

		updatedErrors.name = data.name ? "" : t("Signup.firstNameError");
		updatedErrors.lastName = data.lastName ? "" : t("Signup.lastNameError");
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? t("Login.validEmailError")
				: ""
			: t("Login.emailError");
		updatedErrors.address = data.addressObject?.description ? "" : t("AccountSettings.addressFieldText");
		updatedErrors.phone = data.phone ? "" : t("QualificationDialog.phoneError");
		updatedErrors.bill = data.bill
			? parseFloat(data.bill?.toString() || "") <= 0
				? t("AccountSettings.billError1")
				: ""
			: t("AccountSettings.billError2");

		if (data.password || data.changePassword) {
			updatedErrors.password = validatePassword(data.password);
			updatedErrors.changePassword = validatePassword(data.changePassword);
		}
		console.log("ERRPR", updatedErrors);
		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdateProfile = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			let newEmail;
			if (user.email?.trim() !== data.email?.trim()) {
				newEmail = data.email;
				setUpdatingEmail(newEmail);
			}

			const formData = new FormData();
			if (profilePicture) formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("lastName", data.lastName ?? "");
			formData.append("email", data.email ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("electricity_usage", data.bill?.toString() ?? "");
			formData.append("phone_no", data.phone ?? "");

			formData.append("inappnotification", (!!data.notificationPreferences?.inApp)?.toString());
			formData.append("emailnotification", (!!data.notificationPreferences?.email)?.toString());

			formData.append("current_password", data.password ?? "");
			formData.append("change_password", data.changePassword ?? "");

			await dispatch(updateProfile(formData));

			// we're calling this api to enable notification form BE as well
			await dispatch(getProfile());

			if (newEmail) {
				// setting the old email in input field, if user decides to close the verify otp dialog the input will display the active previous email of user
				setData((state) => ({ ...state, email: user.email }));
				openOtpDialog();
			} else {
				toast.success(t("AccountSettings.profileUpdateSuccessToast"));
			}
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = t("AccountSettings.existingEmailError");
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(allRoutes.WHY_CHOOSE_US);
	};

	const openOtpDialog = () => setOtpDialog(true);
	const closeOtpDialog = () => setOtpDialog(false);

	return (
		<>
			<Navbar />
			<Loader open={loading} />
			<Box px={isSmallScreen ? 32 : 175} py={32}>
				<Typography variant="h5">{t("AccountSettings.settingText")}</Typography>
				<Typography fontSize={15} mt={10}>
					{t("AccountSettings.settingSubText")}
				</Typography>
				<Divider sx={{ mt: 14, mb: 24 }} />

				<form onSubmit={handleUpdateProfile}>
					<Box
						display="grid"
						gridTemplateColumns={{ xs: "1fr", sm: "340px 1fr" }}
						gap={{ xs: 10, sm: 32 }}
						alignItems="center"
					>
						<Box alignSelf="flex-start">
							<Typography variant="h5">{t("AccountSettings.yourPhotoText")}</Typography>
							<Typography fontSize={15} mt={10}>
								{t("AccountSettings.yourPhotoSubText")}
							</Typography>
						</Box>
						<Box>
							<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
						</Box>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("Signup.firstNameLabel")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.name}
							error={errors.name}
							name="name"
							placeholder="First Name"
						/>
						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("Signup.lastNameLabel")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.lastName}
							error={errors.lastName}
							name="lastName"
							placeholder="Last Name"
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("Login.emailLabel")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.email}
							error={errors.email}
							name="email"
							type="email"
							placeholder="@example"
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("AccountSettings.addressText")}
						</Typography>
						<GoogleMapsTextField
							placeholder="Address"
							value={data.addressObject}
							onChange={handleAddress}
							error={errors.address}
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("AccountSettings.billText")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.bill}
							error={errors.bill?.toString()}
							name="bill"
							type="number"
							placeholder="2500"
							endIcon={<>/Mo</>}
							disabled={!!user?.billData?.file || !!user.selectedPlan}
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("QualificationDialog.phoneLabel")}
						</Typography>
						{/* <CustomTextField
							onChange={handleOnChange}
							value={data.phone}
							error={errors.phone}
							name="phone"
							placeholder="+1"
						/> */}
						<MuiPhoneNumber
							defaultCountry={"us"}
							autoComplete="off"
							onChange={(phoneNumber: any) => {
								setData({ ...data, phone: phoneNumber.toString() });
								if (errors.phone) setErrors({ ...errors, phone: "" });
							}}
							fullWidth
							variant="outlined"
							size="small"
							value={data.phone}
							error={!!errors.phone}
							helperText={errors.phone}
							required
							InputLabelProps={{ shrink: true }}
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("AccountSettings.currentPasswordText")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.password}
							error={errors.password}
							name="password"
							type="password"
							placeholder="********"
							autoComplete="new-password"
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							{t("AccountSettings.newPasswordText")}
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.changePassword}
							error={errors.changePassword}
							name="changePassword"
							type="password"
							placeholder="********"
							autoComplete="new-password"
						/>

						{/* <Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
							Notifications
						</Typography>
						<Box display="flex" alignItems="center" gap={8}>
							<CustomCheckBox
								text="In-App"
								checked={!!data.notificationPreferences?.inApp}
								onChange={() => handleClickCheckbox("inApp")}
							/>
							<CustomCheckBox
								text="Email"
								checked={!!data.notificationPreferences?.email}
								onChange={() => handleClickCheckbox("email")}
							/>
						</Box> */}

						<Box />
						<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
							<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
								{t("AccountSettings.cancelText")}
							</CustomButton>
							<CustomButton type="submit">{t("AccountSettings.saveChangesText")}</CustomButton>
						</Box>
					</Box>
				</form>
			</Box>

			<OtpVerifyDialog open={otpDialog} onClose={closeOtpDialog} email={updatingEmail} />
		</>
	);
};

export default AccountSettings;
